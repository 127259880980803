import { ReactNode } from "react";

export function Submit({ children, disabled }: { children: ReactNode; disabled?: boolean }) {
  return (
    <button
      type="submit"
      className="rounded bg-napaYellow w-full p-2 text-napaBlue disabled:bg-zinc-200 disabled:text-zinc-800"
      disabled={disabled}
    >
      {children}
    </button>
  );
}

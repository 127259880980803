import { Icon } from "@iconify/react";
import { ChangeEvent, HTMLInputTypeAttribute, useEffect, useRef, useState } from "react";
import { ZodError } from "zod";

interface FieldProps {
  label: string;
  name: string;
  type?: HTMLInputTypeAttribute;
  errors?: ZodError | any;
  customErrors?: {
    [key: string]: {
      en: string;
      fr: string;
    };
  };
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function Field({
  name,
  label,
  type: baseType = "text",
  errors,
  customErrors,
  onChange,
}: FieldProps) {
  const [type, setType] = useState(baseType);
  const fieldRef = useRef<HTMLInputElement>(null);


  const zodError = errors?.issues?.filter((errors) => {
    return errors.path.includes(name);
  })[0];

  let zodErrorMessage = "";
  if (zodError) {
    try {
      const json = JSON.parse(zodError.message);
      zodErrorMessage = json["en"];
    } catch (e) {
      zodErrorMessage = zodError.message;
    }
  }

  const isError = zodError;

  return (
    <div>
      <label htmlFor={`f_${name}`} className="text-white/60 text-sm">
        {label}
      </label>
      <div className={`relative`}>
        <input
          type={type}
          name={name}
          id={`f_${name}`}
          className={`py-3 bg-black/20 rounded border border-black/20 indent-4 w-full`}
          onChange={onChange}
        />
        {baseType === "password" && (
          <button
            type="button"
            className="absolute top-0 right-2 bottom-0"
            onClick={() => setType(type === "password" ? "text" : "password")}
          >
            <Icon
              icon={type === "text" ? "mdi:eye-lock-open" : `mdi:eye-lock`}
              fontSize={24}
              className="text-slate-500"
            />
          </button>
        )}
      </div>
      {isError && (
        <div className="text-red-500 text-sm">
          {zodError ? zodErrorMessage : 'Unknown error'}
        </div>
      )}
    </div>
  );
}
